<template>
    <div>
        <span v-if="row.item.status == repairStatus.DRAFT">
            <b-badge variant="secondary">{{ row.item.status }}</b-badge>
        </span>
        <span v-if="row.item.status == repairStatus.IN_REPAIR">
            <b-badge variant="success">{{ row.item.status }}</b-badge>
        </span>
        <span v-if="row.item.status == repairStatus.DONE">
            <b-badge variant="primary">{{ row.item.status }}</b-badge>
        </span>
        <span v-if="row.item.status == repairStatus.CANCELLED">
            <b-badge variant="danger">{{ row.item.status }}</b-badge>
        </span>
    </div>
</template>

<script>
// Others
import config from '@/config/env-constants';

export default {
    name: 'repair-row-status',
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            repairStatus: config.repairStatus,
        };
    }
};
</script>