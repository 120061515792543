<template>
    <div>
        <b-tabs>
            <b-tab title="Primary Information" active>
                <b-card-text>
                    <b-form-group label="Repair ID" label-cols-sm="2" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        {{ row.item.repairTaskId }}
                    </b-form-group>

                    <b-form-group label="Remarks" label-cols-sm="2" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <span class="truncate-text">
                            <truncate collapsed-text-class="collapsed" clamp="Show More" :length="100" less="Show Less"
                                type="html" :text="row.item.reasonForRepair" />
                        </span>
                    </b-form-group>

                    <b-form-group label="Status" label-cols-sm="2" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <RepairRowStatus :row="row" />
                    </b-form-group>

                    <b-form-group label="Created By" label-cols-sm="2" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        {{ row.item.createdBy }}
                    </b-form-group>

                    <b-form-group label="Date Created" label-cols-sm="2" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        {{ getFormattedDateWithTime(row.item.dateCreated) }}
                    </b-form-group>

                    <b-form-group label="Updated By" label-cols-sm="2" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        {{ row.item.updatedBy
                            ? row.item.updatedBy
                            : "-" }}
                    </b-form-group>

                    <b-form-group label="Date Updated" label-cols-sm="2" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        {{ row.item.dateUpdated
                            ? getFormattedDateWithTime(row.item.dateUpdated)
                            : "-" }}
                    </b-form-group>
                </b-card-text>
            </b-tab>
            <b-tab title="Asset Details">
                <b-card-text>
                    <b-form-group label="Asset Code" label-cols-sm="2" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        {{ row.item.assetDetails.assetCode }}
                    </b-form-group>

                    <b-form-group label="Asset Type" label-cols-sm="2" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        {{ row.item.assetDetails.details.name }}
                    </b-form-group>

                    <b-form-group label="Status" label-cols-sm="2" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <b-badge v-if="row.item.assetDetails.details.condition === 'Good'" variant="success">
                            Good
                        </b-badge>
                        <b-badge v-else-if="row.item.assetDetails.details.condition === 'Damaged'" variant="danger">
                            Damaged
                        </b-badge>
                        <b-badge v-else variant="secondary">
                            {{ row.item.assetDetails.details.condition
                                ? row.item.assetDetails.details.condition
                                : "-" }}
                        </b-badge>
                    </b-form-group>

                    <b-form-group label="Net Book Value" label-cols-sm="2" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <span class="numFont">
                            {{ row.item.assetDetails.details.netBookValue
                                ? `${row.item.assetDetails.details.netBookValue.toLocaleString("en-US")} PHP`
                                : "-" }}
                        </span>
                    </b-form-group>

                    <b-form-group label="Image" label-cols-sm="2" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <a v-if="row.item.assetDetails.details.imgUrl" href="#"
                            @click.prevent="openImage(row.item.assetDetails.details.imgUrl)">
                            View image
                        </a>
                        <span v-else>-</span>
                    </b-form-group>

                    <b-form-group label="Last Inventory" label-cols-sm="2" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <span class="numFont">
                            {{ row.item.assetDetails.details.inventoriedBy
                                ? `${row.item.assetDetails.details.inventorySessionId} at
                            ${getFormattedDateWithTime(row.item.assetDetails.details.lastInventoryDate)} by
                            ${row.item.maintenance} (${row.item.assetDetails.details.inventoriedBy})`
                                : "-" }}
                        </span>
                    </b-form-group>
                </b-card-text>
            </b-tab>
            <b-tab title="Timeline">
                <b-card-text>
                </b-card-text>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
// Component
import RepairRowStatus from '@/views/maintenance/repair/RepairRowStatus';

// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import EventBus from '@/shared/event-bus';
import truncate from 'vue-truncate-collapsed';
import _ from 'lodash';

export default {
    name: 'repair-details-view',
    components: {
        RepairRowStatus,
        truncate
    },
    props: {
        row: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            currUserId: this.$store.getters.loggedUser.id,
        };
    },
    methods: {
        getFormattedDateWithTime(date) {
            return DateUtil.getFormattedDateWithTime(date);
        },
        openImage(imgUrl) {
            EventBus.$emit('onSelRepairImageView', imgUrl);
            this.$bvModal.show('repair-image-view');
        },
    },
};
</script>