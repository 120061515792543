<template>
    <b-modal :title="'Image'" id="repair-image-view" :no-close-on-backdrop="true" size="md" :hide-footer="true"
        centered>
        <b-row>
            <b-col sm="12">
                <div align="center">
                    <b-img v-img-orientation-changer :src="imgUrl" alt="Responsive image" class="img-responsive"
                        fluid />
                </div>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
// Others
import EventBus from '@/shared/event-bus';
import _ from 'lodash';

export default {
    name: 'repair-image-view',
    data() {
        return {
            imgUrl: ''
        };
    },
    mounted() {
        EventBus.$on('onSelRepairImageView', (data) => {
            this.imgUrl = data;
        });
    },
    beforeDestroy() {
        EventBus.$off('onSelRepairImageView');
    },
};
</script>