<template>
    <b-modal id="cancel-repair" title="Cancel Repair Request" ref="modal" ok-title="Proceed" ok-variant="danger"
        cancel-title="Close" @ok="handleOk" @show="onReset" :cancel-disabled="disableConfirmButtons"
        :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true" centered>
        <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

        <div class="confirm-message">
            Are you sure you want to cancel <b class="numFont">{{ selRepair.repairTaskId }}</b>?
        </div>
    </b-modal>
</template>

<script>
// Util
import { RepairUtil } from '@/utils/repairUtil';

// API
import repairAPI from '@/api/repairApi';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: 'cancel-repair',
    components: {
        Loading,
    },
    data() {
        return {
            selRepair: {},

            currUserId: this.$store.getters.loggedUser.id,

            // Check for loader
            isLoading: false,
        };
    },
    computed: {
        disableConfirmButtons() {
            return this.isLoading;
        },
        repairTaskId() {
            return this.selRepair.repairTaskId;
        },
    },
    mounted() {
        EventBus.$on('onUpdateRepair', (repair) => {
            this.selRepair = repair;
        });
    },
    methods: {
        generateRepair() {
            let repair = RepairUtil.cleanupFields(this.selRepair);

            return {
                ...repair,
                status: config.repairStatus.CANCELLED,
            };
        },
        getParam() {
            let repair = this.generateRepair();

            return {
                currUserId: this.currUserId,
                repair: repair
            };
        },
        async handleOk(evt) {
            // Prevent modal from closing
            evt.preventDefault();

            // show loading indicator
            this.isLoading = true;

            await this.cancelRepair();
        },
        async cancelRepair() {
            try {
                let param = this.getParam();
                let { data } = await repairAPI.cancelRepair(param);

                if (data.isSuccess) {
                    this.$toaster.success(data.message);
                    EventBus.$emit('onCloseRepair', data.repairTask);
                    this.$refs.modal.hide();
                } else {
                    this.$toaster.error(`Error cancelling Repair Request "${this.repairTaskId}". Please try again.`);
                }
            } catch (_error) {
                console.log(_error);
                this.$toaster.error(`Error cancelling Repair Request "${this.repairTaskId}". Please try again.`);
            }

            // hide loading indicator
            this.isLoading = false;
        },
        onReset() {
            // do nothing
        },
    },
    beforeDestroy() {
        EventBus.$off('onUpdateRepair');
    },
};
</script>