<template>
	<b-modal id="add-repair" title="Add Repair Request" size="lg" ref="modal" ok-title="Add" @ok="handleOk"
		@show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true" centered>
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-row class="my-2">
					<b-col sm="8">
						<b>ASSET DETAILS</b>
					</b-col>
				</b-row>
				<b-row class="my-12">
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Asset Type">
							<v-select name="Asset Type" class="style-chooser" label="text"
								placeholder=" - Please select - " :options="assetTypeOptions"
								:reduce="(assetType) => assetType.value" v-model="selAssetType"
								v-validate="'selectRequired'" @input="onChangeAssetType">
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a company
									</em>
								</template>
							</v-select>
							<span v-show="errors.has('Asset Type')" class="help-block">
								{{ errors.first('Asset Type') }}
							</span>
						</b-form-group>
					</b-col>

					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Asset Code" label-for="Asset Code"
							description="List of assets that are marked as Damaged">
							<v-select name="Asset Code" class="style-chooser" label="text"
								placeholder=" - Please select - " :options="assetCodeOptions"
								:reduce="(assetCode) => assetCode.value" v-model="selAssetCode"
								v-validate="'selectRequired'">
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for an asset code
									</em>
								</template>
							</v-select>
							<span v-show="errors.has('Asset Code')" class="help-block">
								{{ errors.first('Asset Code') }}
							</span>
						</b-form-group>
					</b-col>
				</b-row>

				<b-row class="my-12">
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Remarks" label-for="description">
							<b-form-textarea name="Description" type="text" v-model="form.reasonForRepair"
								maxlength="200" v-validate="{
									required: true,
									regex: remarksRegex
								}" :rows="3" placeholder="Description" />
							<span v-show="errors.has('Description')" class="help-block">
								{{ errors.first('Description') }}
							</span>
						</b-form-group>
					</b-col>
				</b-row>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { RepairUtil } from '@/utils/repairUtil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { ValidationUtil } from '@/utils/validationUtil';
import { DateUtil } from '@/utils/dateutil';

// DAO & API
import assetDAO from '@/database/assets';
import repairAPI from '@/api/repairApi';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'add-repair',
	components: {
		Loading,
	},
	props: {
		assetTypeOptions: {
			type: Array,
			required: true,
		}
	},
	data() {
		return {
			form: { ...RepairUtil.getDefaultRepairObj() },

			assetCodeOptions: [],

			allAssetCodesObj: {},

			selAssetType: config.assetTypeDefaultValue,
			selAssetCode: config.assetCodeDefaultValue,

			currUserId: this.$store.getters.loggedUser.id,

			// Check for loader
			isLoading: false,
		}
	},
	watch: {
		selAssetCode(newVal) {
			if (newVal && newVal.id) {
				this.onChangeAssetCode();
			}
		}
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		repairTaskId() {
			return this.form.repairTaskId;
		},
		remarksRegex() {
			return config.remarksRegex;
		}
	},
	mounted() {
		setTimeout(async () => {
			try {
				// show loading indicator
				this.isLoading = true;

				this.fillAssetCodeOptions();
			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			}

			// hide loading indicator
			this.isLoading = false;
		}, config.timeout);
	},
	methods: {
		async onChangeAssetType(newVal) {
			this.selAssetType = newVal;
			this.form.assetType = this.selAssetType;

			try {
				// show loading indicator
				this.isLoading = true;

				this.allAssetCodesObj = await assetDAO.getAssetsByAssetTypeId(this.selAssetType.id);
			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			}

			// hide loading indicator
			this.isLoading = false;

			this.fillAssetCodeOptions(this.selAssetType.id);
		},
		onChangeAssetCode() {
			this.form.assetCode = this.selAssetCode;
		},
		fillAssetCodeOptions(assetTypeId) {
			const filteredObj = _.filter(this.allAssetCodesObj, o => {
				return o.assetTypeId === assetTypeId && o.details.condition === "Damaged";
			});

			if (!_.isEmpty(filteredObj)) {
				// Remove used asset codes
				const filteredRepairs = _.filter(this.$store.getters.repairs,
					repair => repair.status !== config.repairStatus.CANCELLED);

				this.assetCodeOptions = _.filter(DropDownItemsUtil.retrieveAssetCodes(filteredObj), (assetCode) =>
					_.every(filteredRepairs, (repair) => repair.assetDetails.assetCode !== (_.get(assetCode, 'value.id', ''))));
			} else {
				this.assetCodeOptions = [{ value: config.assetCodeDefaultValue, text: '- Please select - ' }];
				this.selAssetCode = config.assetCodeDefaultValue;
			}
		},
		cleanupFormFields() {
			// Removes excess whitespace
			this.form.reasonForRepair = ValidationUtil.removeExcessWhiteSpace(
				this.form.reasonForRepair
			);
		},
		generateRepair(form, assetCodeObj) {
			let currTimestamp = DateUtil.getCurrentTimestamp();

			return {
				status: config.repairStatus.DRAFT,
				repairTaskId: 'RR' + currTimestamp,
				assetDetails: {
					assetCode: assetCodeObj.id,
					details: assetCodeObj.details
				},
				reasonForRepair: form.reasonForRepair,
				createdBy: this.currUserId,
				dateCreated: currTimestamp,
				companyId: this.selAssetType.originId
			};
		},
		getParam() {
			let repair = this.generateRepair(this.form, this.selAssetCode);

			return {
				currUserId: this.currUserId,
				repair: repair,
			};
		},
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			this.cleanupFormFields();

			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');

				// hide loading indicator
				this.isLoading = false;
				return;
			}

			await this.handleSubmit();
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				let param = this.getParam();
				let { data } = await repairAPI.saveRepair(param);

				if (data.isSuccess) {
					this.$toaster.success(data.message);
					EventBus.$emit('onCloseRepair', data.repairTask);
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(`Error saving Repair Request "${this.repairTaskId}". Please try again.`);
				}
			} catch (_error) {
				this.$toaster.error(`Error saving Repair Request "${this.repairTaskId}". Please try again.`);
			}

			// hide loading indicator
			this.isLoading = false;
		},
		onReset() {
			this.form = { ...RepairUtil.getDefaultRepairObj() };

			this.assetCodeOptions = [{ value: config.assetCodeDefaultValue, text: '- Please select - ' }];

			this.selAssetType = config.assetTypeDefaultValue;
			this.selAssetCode = config.assetCodeDefaultValue;

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		}
	}
};
</script>