import axios from 'axios';
import config from '@/config/env-constants';

function getProjectId() {
    if (config.currEnv === 'pjl') {
        return `ayun-${config.currEnv}`;
    } else {
        return `ares-${config.currEnv}-app`;
    }
}

export default {

    baseUrl: `https://us-central1-${getProjectId()}.cloudfunctions.net`,

    getRepair(param) {
        let url = `${this.baseUrl}/getAssetRepairTask`;
        return axios.post(url, {
            currUserId: param.currUserId,
            filterBy: JSON.stringify(param.filterBy),
            view: param.view
        });
    },
    saveRepair(param) {
        let url = `${this.baseUrl}/saveAssetRepairTask`;
        return axios.post(url, {
            currUserId: param.currUserId,
            repairTask: JSON.stringify(param.repair)
        });
    },
    markAsDoneRepair(param) {
        let url = `${this.baseUrl}/markAsDoneAssetRepairTask`;
        return axios.post(url, {
            currUserId: param.currUserId,
            repairTask: JSON.stringify(param.repair)
        });
    },
    startRepair(param) {
        let url = `${this.baseUrl}/startAssetRepairTask`;
        return axios.post(url, {
            currUserId: param.currUserId,
            repairTask: JSON.stringify(param.repair)
        });
    },
    cancelRepair(param) {
        let url = `${this.baseUrl}/cancelAssetRepairTask`;
        return axios.post(url, {
            currUserId: param.currUserId,
            repairTask: JSON.stringify(param.repair)
        });
    },
}