<template>
    <div class="animated fadeIn">
        <b-card class="card-border mt-4">
            <b-card-title><i class="icon-wrench"></i> Repair Management</b-card-title>
            <b-card-sub-title>Handles the management of assets set for repair</b-card-sub-title>
            <div fluid class="px-2 mt-4">
                <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

                <!-- Filter  -->
                <b-row class="mt-2">
                    <b-col sm="12" md="3" lg="3">
                        <b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
                            class="filter">
                            FILTER OPTIONS
                        </b-button>
                    </b-col>
                    <b-col sm="12">
                        <!-- Collapsible Filter Options -->
                        <b-collapse id="collapse-1" class="mt-2">
                            <b-card>
                                <b-row no-gutters>
                                    <b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Date From">
                                            <b-form-datepicker v-model="filterBy.dateFrom" locale="en" reset-button
                                                label-reset-button="Clear" :date-format-options="{
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: '2-digit',
                                                    weekday: 'short',
                                                }" :date-disabled-fn="dateFromDisabled" />
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Date To">
                                            <b-form-datepicker v-model="filterBy.dateTo" locale="en" reset-button
                                                label-reset-button="Clear" :date-format-options="{
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: '2-digit',
                                                    weekday: 'short',
                                                }" :date-disabled-fn="dateFromDisabled" />
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Status">
                                            <b-form-select v-model="filterBy.status" :options="statusOptions"
                                                class="mr-2" />
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Asset Type">
                                            <v-select class="style-chooser" label="text"
                                                placeholder=" - Please select - "
                                                :options="filterByOptions.assetTypeItems"
                                                :reduce="(assetType) => assetType.value" v-model="filterBy.assetType">
                                                <template v-slot:no-options="{ search, searching }">
                                                    <template v-if="searching">
                                                        No results found for
                                                        <em>
                                                            <strong>{{ search }}</strong>
                                                        </em>
                                                    </template>
                                                    <em :style="{ opacity: 0.5 }" v-else>
                                                        Start typing to search for a company
                                                    </em>
                                                </template>
                                            </v-select>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Asset Code">
                                            <b-form-input id="assetCode" name="Asset Code" type="search" class="numFont"
                                                v-model="filterBy.assetCode" />
                                            <span v-show="errors.has('Asset Code')" class="help-block">{{
                                                errors.first('Asset Code') }}</span>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Repair ID"
                                            description="NOTE: Input the exact Repair ID to search">
                                            <b-form-input id="repairId" name="Repair ID" type="search" class="numFont"
                                                v-model="filterBy.repairId"
                                                v-validate="{ required: false, regex: /^RR\d{13}$/ }"
                                                placeholder="RRXXXXXXXXXXXXX" />
                                            <span v-show="errors.has('Repair ID')" class="help-block">{{
                                                errors.first('Repair ID') }}</span>
                                        </b-form-group>
                                    </b-col>
                                    <b-col sm="12">
                                        <b-button class="mr-1" variant="success" @click="onFilterRequest">
                                            Search
                                        </b-button>
                                        <b-button class="mr-1" variant="primary" @click="resetFilters">
                                            Reset
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-collapse>
                    </b-col>
                </b-row>

                <!-- Select Actions and Items Per Page Options -->
                <b-row>
                    <b-col sm="6" md="3" class="mt-4 mb-2">
                        <b-dropdown text=" Select Actions " variant="dark" slot="append">
                            <b-dropdown-item v-b-modal.add-repair v-show="!isViewer">
                                Add Repair Request
                            </b-dropdown-item>
                            <b-dropdown-item>
                                Export Repair Requests in Excel
                            </b-dropdown-item>
                            <b-dropdown-item>
                                Export Repair Requests to CSV
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                    <b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
                        <b-input-group prepend="Show" append="/ Page">
                            <b-form-select :options="pageOptions" v-model="pagination.perPage" />
                        </b-input-group>
                    </b-col>
                </b-row>

                <b-table ref="repairsTable" show-empty striped hover :items="items" :fields="fields"
                    :current-page="pagination.currentPage" :per-page="pagination.perPage" responsive>
                    <template v-slot:cell(repairId)="row">
                        <span class="numFont">{{ row.item.repairTaskId }}</span>
                    </template>

                    <template v-slot:cell(dateCreated)="row">
                        {{ showFormattedDateTime(row.item.dateCreated) }}
                    </template>

                    <template v-slot:cell(assetCode)="row">
                        <span class="numFont">{{ row.item.assetDetails.assetCode }}</span>
                    </template>

                    <template v-slot:cell(status)="row">
                        <RepairRowStatus :row="row" />
                    </template>

                    <template v-slot:cell(actions)="row">
                        <RepairRowActions :row="row" :isViewer="isViewer" />
                    </template>

                    <template slot="row-details" slot-scope="row">
                        <RepairDetailsView :row="row" />
                    </template>
                </b-table>

                <b-row>
                    <b-col md="8" sm="12" class="my-1">
                        <span class="total-display">Total: {{ items.length }}</span>
                    </b-col>
                    <b-col md="4" sm="12">
                        <b-pagination align="right" :total-rows="pagination.totalRows" :per-page="pagination.perPage"
                            v-model="pagination.currentPage" class="my-0" />
                    </b-col>
                </b-row>
            </div>
        </b-card>

        <!-- Modals here -->
        <AddRepair :assetTypeOptions="filterByOptions.assetTypeItems" />
        <EditRepair :assetTypeOptions="filterByOptions.assetTypeItems" />
        <StartRepair />
        <MarkAsDoneRepair />
        <CancelRepair />
        <RepairImageView />
    </div>
</template>

<script>
// Components
import AddRepair from '@/views/maintenance/repair/AddRepair';
import EditRepair from '@/views/maintenance/repair/EditRepair';
import StartRepair from '@/views/maintenance/repair/StartRepair';
import MarkAsDoneRepair from '@/views/maintenance/repair/MarkAsDoneRepair';
import CancelRepair from '@/views/maintenance/repair/CancelRepair';
import RepairDetailsView from '@/views/maintenance/repair/RepairDetailsView';
import RepairRowStatus from '@/views/maintenance/repair/RepairRowStatus';
import RepairRowActions from '@/views/maintenance/repair/RepairRowActions';
import RepairImageView from '@/views/maintenance/repair/RepairImageView';

// DAO & API
import assetDAO from '@/database/assets';
import repairDAO from '@/database/repair';
import repairAPI from '@/api/repairApi';

// Util
import { DateUtil } from '@/utils/dateutil';
import { ValidationUtil } from '@/utils/validationUtil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';

export default {
    name: 'repair',
    components: {
        AddRepair,
        EditRepair,
        StartRepair,
        MarkAsDoneRepair,
        CancelRepair,
        RepairDetailsView,
        RepairRowStatus,
        RepairRowActions,
        RepairImageView,
        Loading,
    },
    data() {
        return {
            items: [],
            fields: [
                {
                    key: 'repairTaskId',
                    label: 'Repair ID',
                    sortable: true,
                },
                {
                    key: 'dateCreated',
                    label: 'Date Created',
                    sortable: true,
                },
                {
                    key: 'assetDetails.assetCode',
                    label: 'Asset Code',
                },
                {
                    key: 'assetType',
                    label: 'Asset Type',
                },
                {
                    key: 'maintenance',
                    label: 'Maintenance',
                },
                {
                    key: 'status',
                },
                {
                    key: 'actions',
                    thClass: 'text-center'
                }
            ],
            pageOptions: [5, 10, 15, 25, 50, 100],
            pagination: {
                perPage: 10,
                currentPage: 1,
                total: 0,
                startAt: '',
                endAt: '',
            },
            defaultFilterBy: {
                dateFrom: moment().format('YYYY-MM-DD'),
                dateTo: moment().format('YYYY-MM-DD'),
                status: null,
                assetType: config.assetTypeDefaultValue,
                assetCode: '',
                repairId: '',
            },
            filterBy: {
                dateFrom: moment().format('YYYY-MM-DD'),
                dateTo: moment().format('YYYY-MM-DD'),
                status: null,
                assetType: config.assetTypeDefaultValue,
                assetCode: '',
                repairId: '',
            },
            prevFilterBy: {},
            filterByOptions: {
                assetTypeItems: [],
            },

            allAssetTypesObj: {},
            allAssetCodesObj: {},
            statusOptions: config.repairStatusOptions,

            allRepairsObj: {},

            isSuperAdmin: this.$store.getters.isSuperAdmin,
            isViewer: this.$store.getters.isViewer,
            loggedUser: this.$store.getters.loggedUser,
            loggedUserCompany: this.$store.getters.loggedUserCompany,

            // Check for loader
            isLoading: false,

            // Listener
            repairListener: null,
        }
    },
    computed: {
        dateFrom() {
            const dateTo = moment();
            const dateFrom = dateTo.add(-30, 'days');
            return dateFrom.format('YYYY-MM-DD');
        },
        dateTo() {
            return moment().format('YYYY-MM-DD');
        },
    },
    mounted() {
        setTimeout(async () => {
            try {
                // Filter Access
                if (this.$store.getters.isScanner
                    || this.$store.getters.isAccounting
                    || this.$store.getters.isApprover) {
                    this.$router.push('/dashboard');
                    this.$toaster.warning('You are not allowed to access this page.');
                }

                // show loading indicator
                this.isLoading = true;

                this.allAssetTypesObj = { ...this.$store.getters.assetTypes };
                this.filterByOptions.assetTypeItems = DropDownItemsUtil.retrieveAssetTypes(this.allAssetTypesObj, true);

                this.defaultFilterBy.dateFrom = this.dateFrom;
                this.defaultFilterBy.dateTo = this.dateTo;

                await this.retrieveAssetCodes();
                await this.resetFilters();
            } catch (_error) {
                this.$toaster.error('Error loading data. Please reload the page again.');
            } finally {
                // hide loading indicator
                this.isLoading = false;
            }
        }, config.timeout);

        // Event Listeners
        EventBus.$on('onCloseRepair', (repairObj) => {
            this.updateTable(repairObj);
        });
    },
    methods: {
        async retrieveAssetCodes() {
            this.allAssetCodesObj = await assetDAO.getAssetsOnCurrCompanyId(this.loggedUserCompany.id);
        },
        async onFilterRequest() {
            let isValid = await this.$validator.validateAll();
            if (!isValid) {
                this.$toaster.warning('Please address the field/s with invalid input.');
                // hide loading indicator
                this.isLoading = false;
                return;
            }

            if (!this.validateFilter()) {
                return;
            }

            if (!_.isEqual(this.filterBy, this.prevFilter)) {
                await this.retrieveData();
                this.prevFilter = { ...this.filterBy };
            }
        },
        async resetFilters() {
            if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
                // reset to default
                this.filterBy = { ...this.defaultFilterBy };
                this.prevFilter = { ...this.filterBy };

                // reset validation
                this.$validator.reset();
                this.errors.clear();

                await this.retrieveData();
            }
        },
        async retrieveData() {
            try {
                // show loading indicator
                this.isLoading = true;

                let param = this.getParam();

                const { data } = await repairAPI.getRepair(param);
                this.allRepairsObj = data.repairTasks;

                this.filterRepairs();

                // Update listener
                this.repairListener = repairDAO.getRepairListener({ ...param.filterBy, isSuperAdmin: this.isSuperAdmin }, this.listenerCallback);
            } catch (_error) {
                this.$toaster.error('Error loading data. Please reload the page again.');
            } finally {
                // hide loading indicator
                this.isLoading = false;
            }
        },
        getParam() {
            let filter = { ...this.filterBy };
            filter.fromTimestamp = DateUtil.startDateTimeStamp(new Date(filter.dateFrom));
            filter.toTimestamp = DateUtil.endDateTimeStamp(new Date(filter.dateTo));
            filter.company = this.loggedUserCompany;

            return {
                filterBy: filter,
                currUserId: this.loggedUser.id,
                view: this.isSuperAdmin ? config.view.ADMIN : config.view.COMPANY
            };
        },
        listenerCallback(type, repair) {
            if ((!this.allRepairsObj[repair.id] && type === "added") || type === "modified") {
                this.allRepairsObj[repair.id] = repair;

                this.filterRepairs();
            }
        },
        updateTable(repairObj) {
            if (_.isEmpty(repairObj)) {
                return;
            }
            this.allRepairsObj[repairObj.id] = repairObj;

            this.filterRepairs();
        },
        filterRepairs() {
            let filteredObjs = { ...this.allRepairsObj };

            let fromTimestamp = DateUtil.startDateTimeStamp(new Date(this.filterBy.dateFrom));
            let toTimestamp = DateUtil.endDateTimeStamp(new Date(this.filterBy.dateTo));

            _.forEach(filteredObjs, (repair, id) => {
                if (repair.dateCreated < fromTimestamp && repair.dateCreated > toTimestamp) {
                    delete filteredObjs[id];
                }

                let status = this.filterBy.status;
                if (status && status.length > 0 && status !== repair.status) {
                    delete filteredObjs[id];
                }

                let assetCode = this.filterBy.assetCode;
                if (assetCode && assetCode.length > 0 && assetCode !== repair.assetCode) {
                    delete filteredObjs[id];
                }

                let assetType = this.filterBy.assetType;
                if (assetType && assetType.length > 0 && assetType.id != repair.assetType.id) {
                    delete filteredObjs[id];
                }

                let repairId = this.filterBy.repairId;
                if (repairId && repairId.length > 0 && repairId !== repair.repairTaskId) {
                    delete filteredObjs[id];
                }
            });

            this.processRepairs(filteredObjs);
        },
        processRepairs(repairs) {
            this.items = Object.values(repairs);
            this.items = _.sortBy(this.items, ['dateCreated']);
            this.items.reverse();

            this.pagination.totalRows = this.items.length;
            this.$store.dispatch('setAllrepairs', repairs);

            // refresh table
            if (this.$refs.repairsTable) {
                this.$refs.repairsTable.refresh();
            }
        },

        resetPagination() {
            this.pagination.startAt = '';
            this.pagination.endAt = '';
        },
        onPageChanged(currentPage) {
            this.pagination.currentPage = currentPage;
        },

        validateFilter() {
            let isValid = true;

            if (_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo)) {
                this.$toaster.warning('Date From and Date To are required.');
                isValid = false;
            } else if ((_.isEmpty(this.filterBy.dateFrom) && !_.isEmpty(this.filterBy.dateTo)) ||
                (!_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo))) {
                this.$toaster.warning('Invalid Date Range. Date From and Date To must both have value.');
                isValid = false;
            } else if (this.filterBy.dateFrom > this.filterBy.dateTo) {
                this.$toaster.warning('Invalid Date Range. Date From must be less than Date To.');
                isValid = false;
            } else if (DateUtil.getNoOfDays(this.filterBy.dateFrom, this.filterBy.dateTo) > 90) {
                this.$toaster.warning('Invalid Date Range. Data range is allowed up to 90 days difference.');
                isValid = false;
            } else if (!this.isValidAssetCode(this.filterBy.assetCode)) {
                this.$toaster.warning(`Invalid Asset Code. "${this.filterBy.assetCode}" doesn't follow any of your asset tagging format.`);
                isValid = false;
            } else if (!ValidationUtil.isAlphaNumeric(this.filterBy.repairId)) {
                this.$toaster.warning('Invalid Repair ID. Please enter a valid Repair ID');
                isValid = false;
            }

            return isValid;
        },
        dateFromDisabled(_ymd, date) {
            return date > new Date();
        },
        isValidAssetCode(assetCode) {
            return _.isEmpty(assetCode) ||
                ValidationUtil.isValidAssetCode(this.allAssetTypesObj, assetCode);
        },
        showFormattedDateTime(date) {
            return DateUtil.getFormattedDateWithTime(date);
        },
    },
    beforeUnmount() {
        if (this.repairListener != null) {
            // Stop listening to changes
            this.repairListener();
        }
    },
    beforeDestroy() {
        EventBus.$off('onCloseRepair');
    },
};
</script>